@import '../../../../styles/color';
@import '../../../../styles/calc';

.box {
  position: relative;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  color: $white-light-color;
  background: linear-gradient(162deg, hsla(0, 0%, 100%, 0.08) 28.7%, hsla(0, 0%, 100%, 0));
  isolation: isolate;
  border-radius: rem(20px);
  margin: rem(15px);
  padding: rem(15px);
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: 0.7;
    background: transparent;
    border-radius: inherit;
    box-shadow: inset 0 0 0 1.5px $white-light-color;
    -webkit-mask-image: radial-gradient(at -31% -58%, rgba(0, 0, 0, 0.5) 34%, transparent 60%),
      linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0, transparent 13%),
      linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    mask-image: radial-gradient(at -31% -58%, rgba(0, 0, 0, 0.5) 34%, transparent 60%),
      linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0, transparent 13%),
      linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  }

  section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    h1 {
      font-size: rem(24px);
    }

    .content {
      padding-top: rem(20px);
    }
  }

  @media (min-width: 1024px) {
    margin: rem(50px) rem(100px);

    section {
      h1 {
        font-size: rem(32px);
      }
    }
  }
}
