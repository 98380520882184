$app-background-color: #131a35; /* fondo de app */
$white-light-color: #ffffff; /* se usa para resaltar cosas del color primario como un link */
$white-primary-color: #e3e3e3; /* seria el color primario */
$cyan-color: #58f3cd; /* border color de botones, TransparentBox e IconBox */
$cyan-dark-color: cyan;
$pink-primary-color: #da2eef; /* para el linear gradient del ColourButton */
$blue-primary-color: #2b6aff; /* para el linear gradient del ColourButton */
$blue-light-color: #39d0d8; /* para el ColourButton y las cards de planes */
$violet-light-color: #7c86bd; /* para el built ON en la main section */
$blue-dark-color: #1b1659; /* para el fondo del plan sections */
$blue-ultra-light-color: #c4d6ff; /* para el texto de los links del footer */
$scrollbar-thumb-color: #945eec;
$scrollbar-shadow-color: rgba(0, 0, 0, 0.3);
