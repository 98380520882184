@import '../../../../styles/color';
@import '../../../../styles/calc';

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  padding: rem(24px);
  height: 100%;

  span:first-of-type {
    color: rgb(255 255 255/1);
    font-weight: 600;
    font-size: rem(18px);
    line-height: rem(28px);
    margin-bottom: rem(8px);
    margin-top: rem(12px);
  }

  span:last-of-type {
    color: rgb(196 214 255/1);
    font-weight: 300;
    font-size: rem(14px);
    line-height: rem(20px);
    margin-bottom: rem(20px);
  }

  .card__button-container {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
}
