@import '../../styles/color.scss';

.line-division {
  background: radial-gradient(39.84% 47.5% at 96.82% 58.33%, $blue-light-color 0%, $blue-primary-color 100%);
  border-radius: 9999px;
  width: 40px;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 8px;
}
