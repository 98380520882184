@import '../../../../styles/calc';

.withdraw-heir {
  display: flex;
  flex-direction: column;
  gap: rem(20px);

  .address-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(20px);

    input {
      width: 80vw;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    padding-top: rem(20px);
  }

  @media (min-width: 768px) {
    .address-container {
      input {
        width: 50vw;
      }
    }
  }
}
