@import '../../styles/_color.scss';

.colour-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(245.22deg, $pink-primary-color 35%, $blue-primary-color 65.17%, $blue-light-color 92.1%);
  background-position: 30% 50%;
  background-size: 150% 150%;
  border-radius: 8px;
  border: 0;
  color: $white-primary-color;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 16px;
  transition: 0.5s ease;
  cursor: pointer;

  &:hover {
    background: linear-gradient(
      245.22deg,
      $pink-primary-color 35%,
      $blue-primary-color 65.17%,
      $blue-light-color 92.1%
    );
  }

  .colour-button-content-container {
    display: flex;
    align-items: center;
    padding: 0;
    gap: 8px;

    .colour-button-icon-container {
      padding-top: 4px;

      .colour-button-svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  @media (min-width: 768px) {
    padding: 10px 20px;
    max-height: 44px;
    font-size: 16px;
  }
}
