@import '../../../../styles/calc';
@import '../../../../styles/color';

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(10px);

  h1 {
    font-size: rem(32px);
  }

  .team-container {
    display: flex;
    flex-direction: column;
    gap: rem(50px);
    margin: rem(10px);

    .content {
      display: flex;
      flex-direction: column;
      gap: rem(20px);
      padding: rem(50px);
      max-width: rem(470px);

      h3 {
        display: flex;
        justify-content: center;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        height: auto;
      }

      a {
        text-decoration: none;
        color: $blue-ultra-light-color;
        font-weight: 600;
      }

      a:hover {
        color: $cyan-dark-color;
      }
    }
  }

  @media (min-width: 1024px) {
    h1 {
      font-size: rem(48px);
    }
    .team-container {
      flex-direction: row;

      .content {
        h3 {
          font-size: rem(24px);
        }
      }
    }
  }
}
