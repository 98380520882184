@import '../../styles/color';

.secondary-button {
  position: relative;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  color: $blue-light-color;
  background: linear-gradient(162deg, rgba(59, 208, 216, 0.08) 28.7%, rgba(59, 208, 216, 0));
  isolation: isolate;
  cursor: pointer;
  padding: 12px;
  border-radius: 12px;
  height: 44px;
  font-weight: 600;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: 0.7;
    background: transparent;
    border-radius: inherit;
    box-shadow: inset 0 0 0 1.5px $cyan-color;
    -webkit-mask-image: radial-gradient(at -31% -58%, rgba(0, 0, 0, 0.5) 34%, transparent 60%),
      linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0, transparent 13%),
      linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    mask-image: radial-gradient(at -31% -58%, rgba(0, 0, 0, 0.5) 34%, transparent 60%),
      linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0, transparent 13%),
      linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  }

  &:hover {
    filter: brightness(0.95);
    -webkit-backdrop-filter: brightness(0.95);
    backdrop-filter: brightness(0.95);
  }
  @media (min-width: 768px) {
    max-height: 44px;
    font-size: 16px;
  }
}
