@import '../../../styles/color';

.transparent-box {
  width: 100%;
  position: relative;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  color: $white-light-color;
  background: linear-gradient(162deg, hsla(0, 0%, 100%, 0.08) 28.7%, hsla(0, 0%, 100%, 0));
  isolation: isolate;
  border-radius: 20px;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: 0.7;
    background: transparent;
    border-radius: inherit;
    box-shadow: inset 0 0 0 1.5px $white-light-color;
    -webkit-mask-image: radial-gradient(at -31% -58%, rgba(0, 0, 0, 0.5) 34%, transparent 60%),
      linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0, transparent 13%),
      linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    mask-image: radial-gradient(at -31% -58%, rgba(0, 0, 0, 0.5) 34%, transparent 60%),
      linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0, transparent 13%),
      linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  }
}
